.table-row-light {
  background-color: #ffffff;
}
.table-row-dark {
  background-color: #f1f3f5;
}
.ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar-animated,
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-ink-bar-animated,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar-animated,
.ant-tabs-bottom > div > .ant-tabs-nav,
.ant-tabs-ink-bar-animated .ant-tabs-ink-bar,
.ant-tabs-ink-bar-animated {
  background-color: #ff4a50;
}

.ant-tabs-tab,
.ant-tabs-tab-active {
  font-size: 1rem;
  width: 5rem;
  text-align: center;
}

.ant-tabs-tab-btn {
  margin: 0 auto;
}
